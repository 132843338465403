<template lang="pug">
om-select-card.onboarding-dashboard-section-card(
  :class="{ done }"
  :title="getTranslation('title')"
  :details="getTranslation('description')"
  @click="$emit('click')"
)
  template(#iconContent)
    img.onboarding-dashboard-section-card-icon(
      v-if="done || icon"
      :src="require(`@/assets/admin/svg/onboarding/${!done ? icon : 'dashboard-section-done'}.svg`)"
    )
  template(#arrow)
    span(v-if="done")
    .onboarding-dashboard-section-card-right(v-else)
      om-body-text.onboarding-dashboard-section-card-right-time(bt400sm v-if="!hideEstimation") {{ getTranslation('readingTime') }}
      inline-svg.brand-select-card-right(
        :src="require('@/assets/admin/svg/icons/chevron-right.svg')"
      )
</template>

<script>
  export default {
    props: {
      icon: { type: String, required: true },
      translationsPrefix: { type: String, required: true },
      hideEstimation: { type: Boolean, required: false },
      done: { type: Boolean, default: false },
    },
    methods: {
      getTranslation(key) {
        return this.$t(`${this.translationsPrefix}.${key}`);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .onboarding-dashboard-section
    &-card
      &.done
        .brand-select-card:hover
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.07)
          cursor: default
    &-card-right
      margin-left: auto
      text-align: right
      min-width: 6.25rem
      &-time.body-text
        display: inline-block
        color: $om-gray-600
    .brand-select-card
      &-title
        line-height: 1.25
      &-with-details
        min-height: 5rem
        height: 100%
        padding-top: 4px
        padding-bottom: 4px
      &-right
        margin-left: 1.5rem
        path
          fill: $om-orange-500
    .brand-svg-transition-bg-background
      display: none
</style>
